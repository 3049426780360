import styled from '@emotion/styled';
import type { GetStaticProps, NextPage } from 'next';
import Link from 'next/link';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { ActionBtn } from 'components/styled/btn';
import { PATH_SWAP } from 'configs/paths';
import { Z_VIDEO, Z_VIDEO_TEXT } from 'configs/zIndex';

const Title = styled.div`
  height: 64px;
  line-height: 64px;
  font-size: 64px;
  font-weight: 900;
`;
const Content = styled.div`
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  line-height: 24px;
`;
const Video = styled.video`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: ${Z_VIDEO};
  object-fit: cover;
`;
const LaunchBtn = styled(ActionBtn)`
  height: 51px;
  padding: 0px ${({ theme }) => theme.gap.xl};
  font-size: 16px;
  font-weight: 500;
  color: ${({ theme }) => theme.color.white};
  background-color: ${({ theme }) => theme.color.point};
  border-radius: ${({ theme }) => theme.radius.md};
  box-shadow: none;
  &:hover,
  &:active {
    box-shadow: none;
  }
`;
const StyledCenter = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 36px;
  flex: 1;
  z-index: ${Z_VIDEO_TEXT};
`;

const Home: NextPage = () => (
  <>
    <Video autoPlay loop muted>
      <source src="/assets/landing_video.mp4" />
    </Video>
    <StyledCenter>
      <Title>Multichain Native</Title>
      <Content>
        The DEX that gets the job done, fast and efficiently.
        <br />
        Simple to use, with low fees.
      </Content>
      <Link href={PATH_SWAP}>
        <LaunchBtn>Launch App</LaunchBtn>
      </Link>
    </StyledCenter>
  </>
);

export const getStaticProps: GetStaticProps = async ({
  locale,
}: {
  locale?: string;
}) => ({
  props: locale
    ? {
        ...(await serverSideTranslations(locale, [
          'common',
          'dialog',
          'tooltip',
          'home',
        ])),
      }
    : {},
});

export default Home;
